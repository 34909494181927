import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import { vipBg } from '@assets/img';

if (process.env.NODE_ENV === 'development') {
  require('./UnderHeader.scss');
  require('./mobile/index.scss');
}
type Props = {
    theme?: any;
    margin?: boolean;
    className?: string;
    children?: React.ReactChild | React.ReactNode;
    style?: any;
};
function UnderHeader(props: Props) {
  const {
    theme,
    margin = false,
    className = '',
    children,
    style,
  } = props;
  return (
    <div style={{ backgroundImage: `url(${vipBg})`, backgroundColor: theme.palette.primary.main, ...style }} className={`under-header ${className} ${(margin) ? 'under-header-margin' : ''}`}>
      {children}
    </div>
  );
}
export default withTheme(UnderHeader);
